<template>
  <Feedback :id="id" :endpoint="endpoint" />
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useLesson from "@/composables/useLesson";
// Components
import Feedback from "@/components/templates/Feedback";

export default {
  components: {
    Feedback
  },
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { endpoint } = useLesson();
    const { getText } = useTexts();

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.feedback")} - ${t("app.lessons", 2)} - ${t("app.teaching")}`;

      const name = getText(props.lesson?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    return {
      documentTitle,
      // useLesson
      endpoint
    };
  }
};
</script>
